#gameProgress {
    color:rgb(0, 0, 0);
    position: fixed;
    margin: 10px;
    top: 0px;
    left: 0px;
    line-height: 45px;
    padding: 15px;
    width: 100px;
    height: 130px;
    background-image: url(../img/gameProgress.png);
    background-repeat: no-repeat;
    background-size: cover;
}