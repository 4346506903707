#gameProgress {
  color: #000;
  width: 100px;
  height: 130px;
  background-image: url("gameProgress.7b781a63.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  padding: 15px;
  line-height: 45px;
  position: fixed;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.544e9304.css.map */
